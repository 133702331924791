import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { HomeComponent } from "./Projects/Shared/Layout/home/home.component";
import { NotFoundComponent } from "./Projects/Shared/Layout/not-found/not-found.component";
// import { AuthGuard } from "./Projects/Shared/Layout/auth/auth.guard";
import { LandingPageComponent } from "./Projects/Shared/Layout/landing-page/landing-page.component";
import { TokenComponent } from "./Projects/Shared/Layout/auth/token/token.component";
import {SKFAuthGuard} from '@skf/auth';

const routes: Routes = [
  { path: "", redirectTo: "auth", pathMatch: "full"},
  { path: "home", component: HomeComponent, canActivate: [SKFAuthGuard] },
  { path: 'auth', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule) },
  { path: "landing", component: LandingPageComponent },
  { path: "token", component: TokenComponent },
  { path: "oauth2/token", component: TokenComponent },
  { path: "adam", loadChildren: () => import('./Projects/Adam/adam.module').then(mod => mod.AdamModule) },
  { path: "application-security", loadChildren: () => import('./Projects/ApplicationSecurity/application-security.module').then(mod => mod.ApplicationSecurityModule) },
  { path: "resource-readiness", loadChildren: () => import('./Projects/EmpHealth/emphealth.module').then(mod => mod.EmphealthModule) },
  // { path: "forecast-file-upload", loadChildren: () => import('./Projects/Forecast/forecast.module').then(mod => mod.ForecastModule) },
  { path: "file-upload-utility", loadChildren: () => import('./Projects/Forecast/forecast.module').then(mod => mod.ForecastModule) },
  { path: "on-demand-quoting", loadChildren: () => import('./Projects/Forecast/forecast.module').then(mod => mod.ForecastModule) },
  { path: "customer-feedback-survey", loadChildren: () => import('./Projects/CustomerFeedback/customer-feedback.module').then(mod => mod.CustomerFeedbackModule) },
  { path: "customer-solution", loadChildren: () => import('./Projects/CustomerSolution/customersolution.module').then(mod => mod.CustomerSolutionModule) },
  { path: "skf-usa-north-america-facilities-team", loadChildren: () => import('./Projects/TeamRoom/teamroom.module').then(mod => mod.TeamRoomModule) },
  { path: "elevate", loadChildren: () => import('./Projects/DSPTool/dsptool.module').then(mod => mod.DSPToolModule) },
  // { path: "compass", loadChildren: () => import('./Projects/CXApp/cxapp.module').then(mod => mod.CXAppModule) },
  { path: "on-site", loadChildren: () => import('./Projects/OnSiteCheckIn/onsitecheckin.module').then(mod => mod.OnSitecheckinModule) },
  { path: "rep", loadChildren: () => import('./Projects/REP/rep.module').then(mod => mod.REPModule) },
  { path: "customer-requests-and-shipping-instructions", loadChildren: () => import('./Projects/LotusNote/lotus-note.module').then(mod => mod.LotusNoteModule) },
  { path: "compass", loadChildren: () => import('./Projects/CXRedesignApp/cxredesign-app.module').then(mod => mod.CXRedesignAppModule) },
  { path: "p2p", loadChildren: () => import('./Projects/P2P/p2p.module').then(mod => mod.P2PModule) },
  { path: "forecasting", loadChildren: () => import('./Projects/Forecasting3Segment/forecasting.module').then(mod => mod.ForecastingModule) },
  { path: "application-security-external-apps", loadChildren: () => import('./Projects/ApplicationSecurity/application-security.module').then(mod => mod.ApplicationSecurityModule) },
  { path: "forecasting-after-market", loadChildren: () => import('./Projects/ForecastingAfterMarket/forecastingAM.module').then(mod => mod.ForecastingAMModule) },
  { path: "application-suite-zipcode", loadChildren: () => import('./Projects/ApplicationSuiteZipcode/application-suite-zipcode.module').then(mod => mod.ApplicationSuiteZipcodeModule) },

  { path: "**", component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
