import {extend} from 'lodash';
import {EnvironmentBaseService} from "src/environments/environment-base.service";


export const environment = extend(EnvironmentBaseService, {
  siteenv: 'dev',
  listDataS3Bucket: 'skf-list-data-dev',
  APIEndpoint: 'https://devappsapi.skfilluminate.net',
  APIEndpointMariaDB: 'https://kgdol3budc.execute-api.us-east-1.amazonaws.com/Stage',
  APISnowflakeEndpoint: 'https://50sgupi0sd.execute-api.us-east-1.amazonaws.com/Stage',
  loginurl: 'https://appsdev-skfilluminate.auth.us-east-1.amazoncognito.com/login?client_id=2o63a0qp0pcp5ermh6eblgrk73&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=https://appsdev3.skfilluminate.net',

});

