<!-- <div style="overflow-x: scroll; "> -->
  <mat-toolbar class="header shadow2">
    <mat-toolbar-row class="shadow2">

      <a routerLink="home" class="brand cursor-pointer">
        <div style="display:flex;">
          <img src="../../assets/images/logo.svg" alt="" class="logo" /><br>
          <img style="height: 24px; margin-left: 10px; position:relative; top: 15px;" src="{{ appLogos[appName] }}" />
        </div>
      </a>

      <span class="menu-spacer"></span>
      <!-- <div fxShow="true" fxHide.lt-md class="desktop-menu">
        <a *ngFor="let item of menuItems" [routerLink]='["../"+item.main[0].state]' routerLinkActive="active-item"
          mat-list-item>{{item.label}}
        </a>
      </div> -->


      <div fxShow="true" fxHide.lt-md class="desktop-menu">
        <!-- The following menu items will be hidden on both SM and XS screen sizes -->
        <ng-container *ngFor="let item of menuItems">
          <a *ngIf="item.submenu==='no';else submenu" [routerLink]='["../"+item.main[0].state]'
             routerLinkActive="active-item" mat-list-item>{{item.label}}
          </a>
          <ng-template #submenu>
            <a [matMenuTriggerFor]="extLink" class="cursor-pointer dropdown-link">
              <span class="dropdown-label">{{item.label}}</span>
              <span class="expand-arrow">
                <mat-icon>expand_more</mat-icon>
              </span>
            </a>
            <mat-menu [class]="'custom-panel'" #extLink="matMenu" xPosition="before">
              <a *ngFor="let link of item.main" mat-menu-item [routerLink]='["../"+link.state]'>
                {{link.name}}
              </a>
            </mat-menu>
          </ng-template>
        </ng-container>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>
<!-- </div> -->
